@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  padding: 0;
  margin: 0;
  font-family: "Rubik", "Source Sans Pro", "Poppins", Arial, Helvetica,
    sans-serif;
}
body * {
  box-sizing: border-box;
}

.MuiPickersCalendarHeader-switchHeader .MuiPickersCalendarHeader-iconButton {
  color: black !important;
}

.mr-5 {
  margin-right: 5px !important;
}
